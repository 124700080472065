import React, { memo } from 'react';
import PropTypes from 'prop-types';

import useVisibilitySettings from './useVisibilitySettings';
import { StoryblokComponent } from 'gatsby-source-storyblok';

const AuthenticationVisibility = ({ visibility, content }) => {
  const isVisible = useVisibilitySettings(visibility);
  return isVisible
    ? content.map((x) => <StoryblokComponent key={x._uid} blok={x} />)
    : null;
};

AuthenticationVisibility.propTypes = {
  visibility: PropTypes.string,
  content: PropTypes.array,
};

export default memo(AuthenticationVisibility);
